<template>
	<v-card flat class="wr_grey_1" height="100%">
		<v-container class="full-height-wr">
			<v-card height="100%">
				<v-card-text class="display-1 font-weight-bold black--text">
					{{ $t("app.can_you_help") }}
				</v-card-text>

				<v-card-text class="mt-5">
					<v-form ref="form">
						<v-select
							v-model="reason"
							:label="$t('app.in_which_part_you_need_help')"
							outlined
							color="greenAccent2"
							background-color="white"
							:items="items"
						>
							<template v-slot:selection="{ item }">
								{{ $t(item) }}
							</template>
							<template v-slot:item="{ item }">
								{{ $t(item) }}
							</template>
						</v-select>

						<TextBox
							v-if="reason == 'Other'"
							v-model="other_reason"
							:prop_value="other_reason"
							:outlined="true"
							:hide_details="false"
							:solo="false"
							:rules="[rules.required]"
						></TextBox>

						<TextBox
							v-model="description"
							:prop_value="description"
							type="v-textarea"
							:outlined="true"
							:hide_details="false"
							:solo="false"
							:rules="[rules.required]"
						></TextBox>
					</v-form>
				</v-card-text>

				<v-card-actions class="send-btn-container-wr">
					<AppButton label="app.continue" icon="send"></AppButton>
				</v-card-actions>
			</v-card>
		</v-container>
	</v-card>
</template>

<script>
import TextBox from "@/components/plugins/TextBox.vue";
import rules from "@/utils/validation";

export default {
	name: "Help",

	data() {
		return {
			rules,
			reason: null,
			description: null,
			other_reason: null,
		};
	},

	components: {
		TextBox,
	},

	computed: {
		items() {
			return [
				"app.choosing_theme",
				"app.creating_mind_map",
				"app.finding_sources",
				"app.write_research.title",
				"app.present.title",
				"app.how_to_use_tool",
				"app.others",
			];
		},
	},
};
</script>

<style>
.send-btn-container-wr {
	width: 100%;
	position: absolute;
	bottom: 0;
}
</style>
